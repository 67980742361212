import { apiConfig } from '../config';

class ApiCall {
  makeJsonApiCall<T>(method: 'POST' | 'GET', path: string, data: any = null, withToken: boolean = true): Promise<T> {
    return new Promise((resolve, reject) => {
      if (typeof data === 'object') {
        data = JSON.stringify(data);
      }
      let request = new XMLHttpRequest();
      request.open(method, apiConfig.url + path, true);
      let token = localStorage.getItem('token');
      console.log(`request: ${method} ${path} ${data} ${token}`);
      if (withToken && token != null) {
        request.setRequestHeader('Authorization', `Bearer ${token}`);
      }
      request.setRequestHeader('Content-type', 'application/json');
      request.addEventListener('load', () => {
        let response = JSON.parse(request.response);
        if (request.status === 200) {
          if (response.success !== undefined && response.success) {
            resolve(response.data);
          } else {
            reject(response.errors);
          }
        } else {
          try {
            reject(response);
          } catch (err) {
            reject(err);
          }
        }
      });
      request.addEventListener(
        'error',
        (reason) => {
          console.log(reason);
          reject(reason);
        },
        false,
      );
      if (method == 'GET') {
        request.send();
      } else {
        request.send(data);
      }
    });
  }
}

let apiCall = new ApiCall();
export { apiCall };
