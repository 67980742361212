import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  Container,
  TableBody,
  Collapse,
  Link,
  IconButton,
  makeStyles,
  Fab,
  Box,
  TablePagination,
  TableFooter,
  TextField,
  Button,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../api";
import { getUsers, FinanceData, logout } from "../api/actions/UserActions";
import { useHistory } from "react-router-dom";

function createData(
  id: number,
  email: string,
  firstname: string,
  lastname: string,
  activated: boolean,
  magentoUserId: number,
  securityCode: number,
  finances: Array<FinanceData>
) {
  return {
    id,
    email,
    firstname,
    lastname,
    activated,
    magentoUserId,
    securityCode,
    finances,
  };
}

const ExpandableRow = (props: { row: ReturnType<typeof createData> }) => {
  const [open, setOpen] = React.useState(false);
  const { row } = props;
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          {row.firstname} {row.lastname}
        </TableCell>
        <TableCell>{row.magentoUserId}</TableCell>
        <TableCell>{row.securityCode}</TableCell>
        <TableCell align="right">{row.activated.toString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="medium" aria-label="payments">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Payment Id</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Transaction Hash</TableCell>
                    <TableCell align="right">Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.finances.map((f) => (
                    <TableRow key={f.id}>
                      <TableCell></TableCell>
                      <TableCell>{f.paymentId}</TableCell>
                      <TableCell component="th" scope="row">
                        {Number(f.amount).toFixed()}
                      </TableCell>
                      <TableCell>{f.bscStatus}</TableCell>
                      <TableCell>
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          href={
                            "https://bscscan.com/tx/" + f.bscTransactionHash
                          }
                        >
                          {f.bscTransactionHash}
                        </Link>
                      </TableCell>
                      <TableCell>{f.bscError}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const UserListPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLImit] = useState(25);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(getUsers(getFilter()));
  }, [page, pageLimit]);

  const classes = useRowStyles();
  const dispatch = useDispatch();
  const usersData = useSelector(
    (state: RootState) => state.UserReducer.userList
  );
  const usersCount = useSelector(
    (state: RootState) => state.UserReducer.usersCount
  );
  const logoutBtn = (e: any) => {
    e.preventDefault();
    dispatch(logout());
    history.push("/");
  };

  const getFilter = useCallback(() => {
    const filter: { limit: number; offset: number; where?: any } = {
      limit: pageLimit,
      offset: page * pageLimit,
    };
    if (searchText.length > 0) {
      filter.where = {
        or: [
          { email: { regexp: `.*${searchText}.*`, options: "i" } },
          { firstname: { regexp: `.*${searchText}.*`, options: "i" } },
          { lastname: { regexp: `.*${searchText}.*`, options: "i" } },
          {
            magentoUserId: isNaN(parseInt(searchText, 10))
              ? undefined
              : searchText,
          },
        ],
      };
    }
    return filter;
  }, [searchText, page, pageLimit]);

  const search = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(getUsers(getFilter()));
    setPage(0);
  };

  const refreshBtn = (e: any) => {
    e.preventDefault();
    dispatch(getUsers(getFilter()));
  };

  return (
    <Container component="main">
      <form onSubmit={search} style={{ display: "flex", margin: "10px 0" }}>
        <TextField
          variant="outlined"
          id="search"
          name="search"
          label="Search email / first name / last name / magento id"
          fullWidth={true}
          defaultValue={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Button variant="contained" type="submit">
          Search
        </Button>
      </form>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Id</TableCell>
            <TableCell>Email address</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Magento Id</TableCell>
            <TableCell>Last Security Code</TableCell>
            <TableCell align="right">Activated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersData.map((row) => (
            <ExpandableRow row={row} key={row.email} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
              count={usersCount}
              page={page}
              onChange={() => {}}
              onPageChange={(e, page) => {
                e?.preventDefault();
                setPage(page);
              }}
              rowsPerPage={pageLimit}
              onRowsPerPageChange={(e) => {
                e.preventDefault();
                setPageLImit(parseInt(e.target.value, 10));
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Fab
        component="button"
        color="primary"
        variant="extended"
        className={classes.fabStyleRefresh}
        onClick={refreshBtn}
      >
        Refresh
      </Fab>
      <Fab
        component="button"
        color="primary"
        variant="extended"
        className={classes.fabStyle}
        onClick={logoutBtn}
      >
        Logout
      </Fab>
    </Container>
  );
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  fabStyle: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  fabStyleRefresh: {
    margin: 0,
    right: 20,
    top: 20,
    left: "auto",
    position: "fixed",
  },
});

export default UserListPage;
