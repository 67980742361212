import { apiCall } from "../ApiCall";
import { Dispatch } from "@reduxjs/toolkit";

type LoginRequest = {
  email: string;
  password: string;
};

export type FinanceData = {
  id: number;
  amount: number;
  bscStatus: string;
  paymentId: string;
  bscTransactionHash: string;
  bscError: string;
};

export type UserData = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  activated: boolean;
  magentoUserId: number;
  securityCode: number;
  finances: Array<FinanceData>;
};

export type LoginResponse = {
  token: string;
};

type loginAction = {
  readonly type: "LOGIN_ACTION";
  readonly payload: string;
};

export const loginAdmin =
  (loginReuqest: LoginRequest) => async (dispatch: Dispatch<loginAction>) => {
    apiCall
      .makeJsonApiCall<LoginResponse>("POST", "/admin/login", loginReuqest)
      .then((response) => {
        console.log(response);

        dispatch({
          type: "LOGIN_ACTION",
          payload: response.token,
        });
      });
  };

type logoutAction = {
  readonly type: "LOGOUT_ACTION";
};

export const logout = () => async (dispatch: Dispatch<logoutAction>) => {
  dispatch({
    type: "LOGOUT_ACTION",
  });
};

type usersAction = {
  readonly type: "USERS_ACTION";
  readonly payload: { users: Array<UserData>; count: number };
};

export const getUsers =
  (filter: { [key: string]: any } = {}) =>
  async (dispatch: Dispatch<usersAction>) => {
    apiCall
      .makeJsonApiCall<{ users: Array<UserData>; count: number }>(
        "GET",
        `/admin/users?filter=${encodeURIComponent(JSON.stringify(filter))}`
      )
      .then((response) => {
        dispatch({
          type: "USERS_ACTION",
          payload: response,
        });
      });
  };

export type Actions = loginAction | logoutAction | usersAction;
