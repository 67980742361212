import * as configLocal from './config.local.json';
import * as configDev from './config.dev.json';
import * as configProd from './config.prod.json';

export class Config {
  url!: string;
}

let apiConfig: Config;

console.log(process.env.REACT_APP_STAGE);
if (process.env.REACT_APP_STAGE === 'development') {
  apiConfig = configDev.api;
} else if (process.env.REACT_APP_STAGE === 'local') {
  apiConfig = configLocal.api;
} else {
  apiConfig = configProd.api;
}

export { apiConfig };
