import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import UserListPage from './pages/UserListPage';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={LoginPage} />
        <Route path="/users" exact component={UserListPage} />
      </Router>
    </div>
  );
};

export default App;
