import { Actions, UserData } from "../actions/UserActions";

export interface IUserState {
  userList: Array<UserData>;
  usersCount: number;
  userToken: string | null;
}

const init: IUserState = {
  userList: [],
  userToken: null,
  usersCount: 0,
};

export function UserReducer(
  state: IUserState = init,
  action: Actions
): IUserState {
  let newState = state;
  switch (action.type) {
    case "LOGIN_ACTION":
      localStorage.setItem("token", action.payload);
      newState = { ...state, userToken: action.payload };
      break;
    case "LOGOUT_ACTION":
      localStorage.removeItem("token");
      newState = { ...state, userToken: null, userList: [] };
      break;
    case "USERS_ACTION":
      newState = {
        ...state,
        userList: action.payload.users,
        usersCount: action.payload.count,
      };
      break;
    default:
      return state;
  }
  return newState;
}
