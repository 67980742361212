import React, { useEffect } from 'react';
import { createStyles } from '@material-ui/core/styles';
import { TextField, Button, withStyles, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdmin } from '../api/actions/UserActions';
import { RootState } from '../api';

interface ILoginPageProps {
  classes?: any;
}

const LoginPage = (props: ILoginPageProps) => {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const isLoggedIn = useSelector((state: RootState) => {
    return state.UserReducer.userToken != null;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/users');
    }
  });
  function handleClick(e: any) {
    e.preventDefault();
    dispatch(loginAdmin({ email: email, password: password }));
  }

  function onDataChanged(e: any, field: string) {
    if (field === 'email') {
      setEmail(e.target.value);
    }
    if (field === 'password') {
      setPassword(e.target.value);
    }
  }

  return (
    <Box maxWidth="xs" minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
      <div className={props.classes.root}>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => onDataChanged(e, 'email')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="password"
            autoFocus
            onChange={(e) => onDataChanged(e, 'password')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={props.classes.submit}
            onClick={handleClick}
          >
            Sign in
          </Button>
        </form>
      </div>
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: 'normal',
  },
});

export default withStyles(styles as any)(LoginPage) as any;
